export default [
  {
    path: "/",
    text: "Home",
  },
  {
    path: "/about",
    text: "About",
  },
  {
    path: "/service",
    text: "Service",
  },
  {
    path: "/wiki",
    text: "Wiki",
  },
  {
    path: "/showroom",
    text: "Showroom",
  },
]
