import React, { useState, useEffect } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Navbar from "./navbar"
import Footer from "./footer"
import { FaAngleUp } from "react-icons/fa"
import "./layout.css"

const Layout = ({ children, location }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, [])

  return (
    <main id="top">
      <Navbar />
      {children}
      <div className="scroll-top" style={{ opacity: offset > 200 ? 1 : 0 }}>
        <AnchorLink to={location + "#top"} className="btn-primary">
          <FaAngleUp />
        </AnchorLink>
      </div>
      <Footer />
    </main>
  )
}

export default Layout
