import React from "react"
import { FaLinkedinIn, FaFacebook, FaTwitter, FaEnvelope } from "react-icons/fa"

export default [
  {
    icon: <FaLinkedinIn />,
    url:
      "https://www.linkedin.com/shareArticle?mini=true&url=https://vscovidunited.de/&title=&summary=Share #VsCovidUnited&source=",
  },
  {
    icon: <FaFacebook />,
    url:
      "https://www.facebook.com/sharer/sharer.php?u=https://vscovidunited.de/",
  },
  {
    icon: <FaTwitter />,
    url:
      "https://twitter.com/home?status=https://vscovidunited.de/ Share #VsCovidUnited",
  },
  {
    icon: <FaEnvelope />,
    url:
      "mailto:kontakt@vscovidunited.de?&subject=&body=https://vscovidunited.de/ Share #VsCovidUnited",
  },
]
