export default [
  {
    path: "/",
    text: "Home",
  },
  {
    path: "/rules",
    text: "Regeln",
  },
  {
    path: "/imprint",
    text: "Impressum",
  },
  {
    path: "/privacy",
    text: "Datenschutz",
  },
  {
    path: "/contact",
    text: "Kontakt",
  },
]
