import React, { useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "../css/navbar.module.css"
import headerLinks from "../constants/headerLinks"
import socialIcons from "../constants/social-icons"
import { FaAlignRight } from "react-icons/fa"
import logo from "../images/logo.svg"

const Navbar = () => {
  const [isOpen, setNav] = useState()
  const toggleNav = () => {
    setNav((isOpen) => !isOpen)
  }

  return (
    <nav className={styles.navbar}>
      <div className={styles.navCenter}>
        <div className={styles.navHeader}>
          <AniLink fade to="/">
            <img src={logo} alt="#VsCovidUnited Logo" />
          </AniLink>
          <button type="button" className={styles.logoBtn} onClick={toggleNav}>
            <FaAlignRight className={styles.logoIcon} />
          </button>
        </div>
        <ul
          className={
            isOpen
              ? `${styles.navLinks} ${styles.showNav}`
              : `${styles.navLinks}`
          }
        >
          {headerLinks.map((item, index) => {
            return (
              <li key={index}>
                <AniLink
                  fade
                  to={item.path}
                  className={styles.headerLinks}
                  activeClassName="active"
                >
                  {item.text}
                </AniLink>
              </li>
            )
          })}
          <li>
            <AniLink fade to={"/join"} activeClassName="active">
              <span>Join</span>
            </AniLink>
          </li>
        </ul>
        <div className={styles.navSocialLinks}>
          {socialIcons.map((item, index) => {
            return (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.icon}
              </a>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
